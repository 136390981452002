import {AnalyticsProvider} from '@github-ui/analytics-provider'
import type {FileBlobPagePayload, FileTreePagePayload} from '@github-ui/code-view-types'
import {CurrentRepositoryProvider} from '@github-ui/current-repository'
import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {ScreenSize, ScreenSizeProvider} from '@github-ui/screen-size'
import {CodeViewOptionsProvider} from '@github-ui/use-code-view-options'
import React, {useEffect, useMemo} from 'react'

import {FocusHintContextProvider} from '../react-shared/contexts/FocusHintContext'
import {CurrentUserProvider} from '../react-shared/Repos/CurrentUser'

export function App({children}: {children?: React.ReactNode}) {
  const payload = useRoutePayload<FileBlobPagePayload | FileTreePagePayload>()
  const [repo] = React.useState(payload?.repo)
  const [user] = React.useState(payload?.currentUser)
  const appName = 'react-code-view'
  const category = ''
  const metadata = useMemo(() => ({}), [])

  useEffect(() => {
    const footer = document.querySelector('.footer')
    if (footer) {
      footer.querySelector('.mt-6')?.classList.replace('mt-6', 'mt-0')
      footer.querySelector('.border-top')?.classList.remove('border-top')
    }
  }, [])

  return (
    <>
      {/* While this meta tag is often in the <head>, in the case where we turbo nav from a page that didn't have this
          meta tag in the <head>, it won't get placed in the <head>, so we render it here ourselves so that it exists
          _somewhere_.
          We must keep the one in the <head> though, because some vitals are sent before react renders so having the
          meta tag here alone is insufficient. */}
      <meta data-hydrostats="publish" />
      <ScreenSizeProvider initialValue={ScreenSize.xxxlarge}>
        <AnalyticsProvider appName={appName} category={category} metadata={metadata}>
          <CurrentUserProvider user={user}>
            <CurrentRepositoryProvider repository={repo}>
              <CodeViewOptionsProvider>
                <FocusHintContextProvider>{children}</FocusHintContextProvider>
              </CodeViewOptionsProvider>
            </CurrentRepositoryProvider>
          </CurrentUserProvider>
        </AnalyticsProvider>
      </ScreenSizeProvider>
    </>
  )
}

try{ App.displayName ||= 'App' } catch {}